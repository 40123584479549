<template>
  <div>
    <base-section
      id="serviceprovideragenda-account"
      space="16"
    >
      <v-container>
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="6"
          >
            <base-info-card :title="$t('topMenuEU.agenda')" />
          </v-col>
        </v-row>
        <v-row>
          <kdw-Calendar
            :key="'Calendar' + trickDisplay"
            v-model="resourceList"
          />
        </v-row>
      </v-container>
    </base-section>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator'
  import { ResourceList } from '@/services/ResourceList'

  @Component({ name: 'ServiceProviderCalendarAccount' })
  class ServiceProviderCalendarAccount extends Vue {
    resourceList = new ResourceList()
    trickDisplay = 0
    created () {
      this.resourceList.loadResourcesCalendar()
        .then(() => {
          this.trickDisplay++
          // console.log('this.resourceList:-----------------------------')
          // console.log(this.resourceList)
        })
    }
  }

  export default ServiceProviderCalendarAccount
</script>
